import { FedexDomesticDefaultValues } from './FedexDomesticDefaultValues';

export const FedexDomestic = {
  clientEntityState: 4,
  id: '',
  tenantId: '',
  clientId: '',
  configurationName: '',
  password: '',
  courier: '',
  courierCode: 'FedexDomestic',
  integration: 'FedexDomestic',
  additionalServices: {
    clientEntityState: 4,
    id: '',
    tenantId: '',
    services: [],
  },
  accessCode: '',
  url: '',
  paymentForm: '',
  shipmentType: 'K',
  payerType: '',
  senderId: '',
  payerId: '',
  labelFormat: 'PDF',
  remarks: '',
  defaultValues: FedexDomesticDefaultValues,
};
