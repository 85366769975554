export const FedexDomesticDefaultValues = {
  clientEntityState: 4,
  id: '',
  tenantId: '',
  senderSignature: '',
  courierId: '',
  bankAccountNumber: '',
  insurance: {
    clientEntityState: 4,
    id: '',
    tenantId: '',
    amount: 0,
    description: '',
  },
  tenderService: {
    clientEntityState: 4,
    id: '',
    tenantId: '',
    isOn: false,
    argumentValue: '',
  },
  loadService: {
    clientEntityState: 4,
    id: '',
    tenantId: '',
    isOn: false,
    argumentValue: '',
  },
  unloadService: {
    clientEntityState: 4,
    id: '',
    tenantId: '',
    isOn: false,
    argumentValue: '',
  },
  carryInService: {
    clientEntityState: 4,
    id: '',
    tenantId: '',
    isOn: false,
    argumentValue: '',
  },
  directSignatureService: {
    clientEntityState: 4,
    id: '',
    tenantId: '',
    isOn: false,
    argumentValue: '',
  },
  courierCallService: {
    clientEntityState: 4,
    id: '',
    tenantId: '',
    isOn: false,
    argumentValue: '',
  },
  carryOutAtShipperService: {
    clientEntityState: 4,
    id: '',
    tenantId: '',
    isOn: false,
    argumentValue: '',
  },
  carryOutAtRecipientService: {
    clientEntityState: 4,
    id: '',
    tenantId: '',
    isOn: false,
    argumentValue: '',
  },
  nextDay9Service: {
    clientEntityState: 4,
    id: '',
    tenantId: '',
    isOn: false,
    argumentValue: '',
  },
  nextDay10Service: {
    clientEntityState: 4,
    id: '',
    tenantId: '',
    isOn: false,
    argumentValue: '',
  },
  nextDay12Service: {
    clientEntityState: 4,
    id: '',
    tenantId: '',
    isOn: false,
    argumentValue: '',
  },
  docReturnService: {
    clientEntityState: 4,
    id: '',
    tenantId: '',
    isOn: false,
    argumentValue: '',
  },
  collectDeliveryService: {
    clientEntityState: 4,
    id: '',
    tenantId: '',
    isOn: false,
    argumentValue: '',
  },
  homeDeliveryService: {
    clientEntityState: 4,
    id: '',
    tenantId: '',
    isOn: false,
    argumentValue: '',
  },
  marketDeliveryService: {
    clientEntityState: 4,
    id: '',
    tenantId: '',
    isOn: false,
    argumentValue: '',
  },
  podEmailService: {
    clientEntityState: 4,
    id: '',
    tenantId: '',
    isOn: false,
    argumentValue: '',
  },
  dispatchProofEmailService: {
    clientEntityState: 4,
    id: '',
    tenantId: '',
    isOn: false,
    argumentValue: '',
  },
  eveningDeliveryService: {
    clientEntityState: 4,
    id: '',
    tenantId: '',
    isOn: false,
    argumentValue: '',
  },
  saturdayDeliveryService: {
    clientEntityState: 4,
    id: '',
    tenantId: '',
    isOn: false,
    argumentValue: '',
  },
  personalizedDeliveryService: {
    clientEntityState: 4,
    id: '',
    tenantId: '',
    isOn: false,
    argumentValue: '',
  },
  emailAlertService: {
    clientEntityState: 4,
    id: '',
    tenantId: '',
    isOn: false,
    argumentValue: '',
  },
  smsAlertService: {
    clientEntityState: 4,
    id: '',
    tenantId: '',
    isOn: false,
    argumentValue: '',
  },
  euPalletReturnService: {
    clientEntityState: 4,
    id: '',
    tenantId: '',
    isOn: false,
    argumentValue: '',
  },
  epPalletReturnService: {
    clientEntityState: 4,
    id: '',
    tenantId: '',
    isOn: false,
    argumentValue: '',
  },
  returnPackService: {
    clientEntityState: 4,
    id: '',
    tenantId: '',
    isOn: false,
    argumentValue: '',
  },
  returnLabelService: {
    clientEntityState: 4,
    id: '',
    tenantId: '',
    isOn: false,
    argumentValue: '',
  },
};
